import "./src/styles/main.scss"

function getCookie(n) {
  const values = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`)
  return values ? values[1] : ""
}

export const onClientEntry = () => {
  if (window.location.search) {
    document.cookie =
      "incomingQueryParameters=" + window.location.search + "; path=/"
  }
}

export const onRouteUpdate = () => {
  const incomingQueryParams = getCookie("incomingQueryParameters")
  if (incomingQueryParams !== "") {
    for (let i = 0; i < document.links.length; i++) {
      const element = document.links[i]
      if (!element.href.startsWith("mailto:")) {
        const url = new URL(element.href)
        // Only append to external links

        if (url.host !== new URL(window.location).host) {
          element.href += incomingQueryParams
        }
      }
    }
  }
}
